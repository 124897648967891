


import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ components: { } })
export default class ConfirmModal extends Vue {
  @Prop({ required: true }) message;
  @Prop({ required: true }) title;
  @Prop({ required: true }) notConfirmButton;
  @Prop({ required: true }) confirmButton;

  public confirm(isConfirmed) {
      if(isConfirmed) {
          this.$emit('onConfirm');
      } else {
          this.$emit('onNotConfirm');
      }
  }
}
