

























































































































































































































































































































































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import { ALLOWED_FILE_EXTENSIONS } from "@/constants";
import Axios from "axios";
import DisplayFiles from "@/views/DisplayDocument.vue";
import ConfirmModal from "@/components/urla/los/generic/ConfirmModal.vue";
import CommonMixin from "@/mixins/common.mixin";

@Component({
  components: {
    DisplayFiles,
    ConfirmModal
  }
})
export default class UpdateUserInfoComponent extends CommonMixin {
  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  @Prop()
  public propData: object;
  public newComment = null;
  public errorInComment = false;
  public attachedFiles: any = [];
  public componentData: any = null;
  public countFiles = 0;
  public comments: any = null;
  public localFiles = [];
  public fileChange = false;

  public lenders = [];
  public borrowerInfo: any = [];
  public lenderData: any = {
    name: null,
    lenderWebSite: null,
    password: null,
    userName: null,
    accountExecutiveFirstName: null,
    accountExecutiveLastName: null,
    lenderPhone: null,
    lenderEmail: null,
    addedByUserType: this.$userType
  };
  public inProcessComplete = false;
  public haveActions = false;
  public borrowerInfoData = null;
  public userLoader = false;
  public duplicateEmailArray = [];
  public messageShow = false;
  public errorIndex = [];
  public sendVerificationMail: boolean = true;
  public blockResend = false;
  public blockMessage = null;
  public showLenderListDropdown = false;
  public selectedLender = null;
  public primaryLender = null;
  public wemloCredentails = {
    username : null, password : null
  }
  public getUserType() {
    return this.$store.state.sessionObject.type;
  }

  public checkDuplicateEmail(email, i) {
    if (this.borrowerInfo.length > 1) {
      if (this.duplicateEmailArray.indexOf(email) == -1) {
        this.duplicateEmailArray.push(email);
        this.errorIndex[i] = null;
      } else {
        this.errorIndex[i] = true;
        this.messageShow = true;
        return;
      }
    } else {
      return;
    }
  }
  /**
   * from here we are updating lender information
   */
  public async updateLenderInfo() {
    try {
      let formValid = await this.$validator.validateAll();
      if (!formValid) {
        return;
      }
      if(this.selectedLender && this.propData[0]) {
        this.propData[0].lenderName = this.selectedLender;
        this.propData[0].wemloPassword = this.wemloCredentails.password;
        this.propData[0].wemloUserName =  this.wemloCredentails.username;
      }
      let response = await Axios.post(
        BASE_API_URL + "loan/updateLenderInfo",
        {
          borrowerInfo: this.propData[0],
          lenderInfo: this.lenderData,
          addedBy: this.$userId
        });
      this.hideModal();
      if (response.data.hasOwnProperty("message")) {
        if (this.propData[0]["lenderName"] == "Others") {
          this.inProcessComplete = true;
          let lender = {
            name: this.lenderData.name,
            id: response.data.lenderId
          };
          this.propData[0].lenderName = lender;
        }

        this.$snotify.success("Updated successfully");
        this.$emit("callMountFunction", this.propData);
      }
      this.inProcessComplete = false;
    } catch (error) {
      this.inProcessComplete = false;
      console.log(error);
    }
  }

  /**
   * from here we are updating Borrower information
   */
  public async updateBorrowerPersonalInfo() {
    try {
      this.blockResend =false;
      let formValid = await this.$validator.validateAll();
      if (this.messageShow) {
        let j = 0;
        for (j = 0; j < this.errorIndex.length; j++) {
          if (this.errorIndex[j] == true) {
            return;
          }
        }
      }
      this.borrowerInfo.forEach(el => {
        el.haveChanges = false;
      });
      if (this.propData[0]["modalName"] == "emailUpdate") {
        this.borrowerInfo.forEach((el, i) => {
          if (
            el.userType == "Wemlo Processor" ||
            el.userType == "MLO" ||
            el.userType == "Admin Staff" ||
            el.userType == "Broker"
          ) {
            if (el.action && this.propData[0].email == el.email)
              el.haveChanges = true;
          } else {
            if (
              el.action &&
              this.borrowerInfo[i].personalInfo.contactInformation.email ==
                el.email
            )
              el.haveChanges = true;
          }
        });
      } else if (this.propData[0]["modalName"] == "cellPhoneUpdate") {
        // this.borrowerInfo.forEach((el, i) => {
        //   if (
        //     el.action &&
        //     this.borrowerInfo[i].personalInfo.contactInformation.cellPhone ==
        //       el.cellPhone
        //   )
        //     el.haveChanges = true;
        // });
        this.borrowerInfo.forEach((el, i) => {
          if (
            el.userType == "Wemlo Processor" ||
            el.userType == "MLO" ||
            el.userType == "Admin Staff" ||
            el.userType == "Broker"
          ) {
            if (el.action && this.propData[0].cellPhone == el.cellPhone)
              el.haveChanges = true;
          } else {
            if (
              el.action &&
              this.borrowerInfo[i].personalInfo.contactInformation.email ==
                el.email
            )
              el.haveChanges = true;
          }
        });
      } else if (this.propData[0]["modalName"] == "commentModal") {
        this.borrowerInfo.forEach((el, i) => {
          el.haveChanges = true;
        });
      }
      await this.checkDuplicates();
      let haveChanges = this.borrowerInfo.find(
        a => a.haveChanges || a.duplicateEmail
      );
      let haveAction = this.borrowerInfo.filter(a => a.action);
      if (haveAction.length == 0) {
        this.haveActions = true;
        return;
      }
      if (haveChanges || !formValid) return;
      this.inProcessComplete = true;
      let response = await Axios.post(
        BASE_API_URL + "loan/updateUserInfo",
        { haveAction, sendEmail: this.sendVerificationMail });

      if (response.data.status == "success") {
        this.$snotify.success(response.data.message);
        this.hideModal();
        this.$emit("callMountFunction", response.data.data);
      } else if (response.data.status == "DuplicateEmail") {
        response.data.data.haveAction.forEach(el => {
          this.borrowerInfo.forEach((element, i) => {
            if (el.userId == element.userId) {
              this.borrowerInfo[i] = el;
            }
          });
        });
      }
      this.inProcessComplete = false;
      this.messageShow = false;
      this.errorIndex = [];
      this.duplicateEmailArray = [];
      this.sendVerificationMail = false;
    } catch (error) {
      this.inProcessComplete = false;
      console.log(error);
    }
  }

  /*****************************************************************************************************************************
   *      In this function check local duplicate phone and Email                                                               *
   *****************************************************************************************************************************/
  public async checkDuplicates() {
    try {
      this.blockResend = false;
      this.borrowerInfo.forEach(el => {
        el.duplicateEmail = false;
        el.haveChanges = false;
      });
      if (this.propData[0]["modalName"] == "emailUpdate") {
        //this.borrowerInfo.forEach((element, index) => {
        this.borrowerInfo.forEach((el, i) => {
          if (
            // element.userType == "Wemlo Processor" ||
            // element.userType == "MLO" ||
            // element.userType == "Admin Staff" ||
            // element.userType == "Broker"
            el.userType == "Wemlo Processor" ||
            el.userType == "MLO" ||
            el.userType == "Admin Staff" ||
            el.userType == "Broker"
          ) {
            if (
              el.action &&
              //index < i &&
              this.propData[0].email == el.email
            ) {
              el.duplicateEmail = true;
              el.haveChanges = true;
            }
          } else {
            if (
              el.action &&
              //index < i &&
              this.borrowerInfo[i].personalInfo.contactInformation.email ==
                el.email
            ) {
              el.duplicateEmail = true;
              el.haveChanges = true;
            }
          }
        });
        //});
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * getLendersList
   */

   /**
   * getLendersList
   */
  public async getLendersList(data) {
    try {
      let body = {
        brokerId: data.brokerId,
        userType: this.$userType,
        userId: this.$userId,
        lenderInfo: data
      };
      let response = await Axios.post(
        BASE_API_URL + "broker/getBrokerLenderList",
        body);
    if(response.data){
      this.primaryLender  = this.propData[0] && this.propData[0].lenderName;
      this.selectedLender =  this.primaryLender;
      this.lenders = response.data.lenderData;
      this.getLenderCredential();
    }
    } catch (error) {
      console.log(error);
    }
  }

    @Watch('selectedLender')
    checkSelectedLender() {

    }

  public async getLenderCredential() {
    let filteredLender = null;
    if(this.lenders){
      filteredLender = this.lenders.find( lender => { return lender.lenderName == this.selectedLender['name']});
    }
    if(filteredLender){
       this.wemloCredentails.username = filteredLender.wemloUserName;
       this.wemloCredentails.password = filteredLender.wemloPassword;
    }
  }

  /**
   * resendVerificationLink
   */
  public async resendVerificationLink() {
    try {
      this.blockResend = false;
      let formValid = await this.$validator.validateAll();
      let haveAction = this.borrowerInfo.find(a => a.action);
      if (!haveAction) {
        this.haveActions = true;
        return;
      }
      if (!formValid && this.blockResend) return;
      let resendEmails: any = [];
      let emailVerified = [];


      this.borrowerInfo.forEach(el => {
        if (el.userType == "Borrower") {
          if (el.action && el.email != el.personalInfo.contactInformation.email) {
            this.blockResend = true;
            return
          }
        } else if (
          el.userType == "Wemlo Processor" ||
          el.userType == "MLO" ||
          el.userType == "Admin Staff" ||
          el.userType == "Broker"
        ) {
          if (el.action && el.email != this.propData[0].email) {
            this.blockResend = true;
            return
        }
        }
          if (el.action && !el.isEmailVerified) {
            resendEmails.push({
              _id: el.userType == "Broker" ? el._id : el.userId,
              email: el.email,
              userType: el.userType,
              action: 'resend',
              sendEmail: this.sendVerificationMail,
              name:
                el.userType == "Borrower"
                  ? `${el.personalInfo.name.firstName} ${el.personalInfo.name.lastName}`
                  : `${el.firstName} ${el.lastName}`
            });
          }

          if (el.action && el.isEmailVerified)
            emailVerified.push(
              el.userType == "Borrower"
                ? `${el.personalInfo.name.firstName} ${el.personalInfo.name.lastName}`
                : `${el.firstName} ${el.lastName}`
            );
      });

      if (!this.blockResend) {
        if (resendEmails.length > 0) {
          let response = await Axios.post(
            BASE_API_URL + "authentication/resendMailVerificationLink",
            {
              userInfo: resendEmails,
              userType: this.borrowerInfo[0].userType
            });
          let names = resendEmails.map(a => a.name);
          this.$snotify.success(
            `${names.join(", ")}: Email verification link sent successfully.`
          );
        }
        if (emailVerified.length > 0) {
          this.$snotify.info(
            `${emailVerified.join(", ")}: Email is already verified.`
          );

        }
        this.hideModal();
      } else {
        this.blockMessage = "You Need to Update Email First";
      }
    } catch (error) {
      console.log(error);
    }
  }

  public toggleLenderInfoDrpdown() {
    this.showLenderListDropdown = !this.showLenderListDropdown;
    this.selectedLender = this.primaryLender;
    this.getLenderCredential()
  }

  public hideModal() {
    this.errorIndex = [];
    this.blockResend =false;
    this.messageShow = false;
    this.showLenderListDropdown = false;
    this.duplicateEmailArray = [];
    this.borrowerInfo = [];
    this.selectedLender = null;
    this.$modal.hide("emailUpdate");
    this.$modal.hide("nameUpdate");
    this.$modal.hide("cellPhoneUpdate");
    this.$modal.hide("commentModal");
    this.$modal.hide("lenderInfoUpdate");
    this.$emit("closeModal", false);
  }

  public async openActiveOrDeactiveModal(userInfo) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/getAllUsers",
        {
          loanTxnId:
            userInfo[0].userType == "Wemlo Processor" ||
            userInfo[0].userType == "MLO" ||
            userInfo[0].userType == "Admin Staff" ||
            userInfo[0].userType == "Broker"
              ? userInfo[0].userId
              : userInfo[0].loanTxnId,
          userType: userInfo[0].userType,
          userActive: userInfo[0].userActive
        });
      this.borrowerInfo = response.data;
      this.$modal.show(userInfo[0]["modalName"]);
      this.borrowerInfo = this.borrowerInfo.map((a, i) => {
        return {
          ...a,
          duplicateCellPhone: false,
          duplicateEmail: false,
          haveChanges: false,
          modalName: userInfo[0]["modalName"],
          userType: userInfo[0].userType,
          loanTxnId:
            userInfo[0].userType == "Wemlo Processor" ||
            userInfo[0].userType == "MLO" ||
            userInfo[0].userType == "Admin Staff" ||
            userInfo[0].userType == "Broker"
              ? userInfo[0].userId
              : userInfo[0].loanTxnId,
          action: this.borrowerInfo.length == 1 ? true : false,
          firstName: userInfo[0].firstName,
          lastName: userInfo[0].lastName,
          inActiveBroker: userInfo[0].inActiveBroker
        };
      });
      this.userLoader = false;
    } catch (error) {
      this.userLoader = false;
      console.log(error);
    }
  }

  async openModalOrClose(data) {
    await this.openActiveOrDeactiveModal(data);
    if (data[0]["modalName"] == "lenderInfoUpdate") {
      this.getLendersList(data[0]);
    }
  }

    // TO COPY TEXT IN INPUT FIELD
  public async copyUserName() {
    var copyText: any = document.getElementById("userName");
    copyText.select();
    document.execCommand("copy");
    this.$snotify.success("Username copied");
  }
  public async copyUserPassword() {
    var copyText: any = document.getElementById("password");
    copyText.select();
    document.execCommand("copy");
    this.$snotify.success("Password copied");
  }

  // ------------Save function for Adding  Comment-------------/
  public async addComment() {
    try {
      if (!this.newComment) {
        this.errorInComment = true;
        return;
      }
      this.$store.state.wemloLoader = true;
      let formData = new FormData();
      this.attachedFiles.forEach(e => {
        formData.append("file", e);
      });
      formData.append("loanTxnId", JSON.stringify(this.borrowerInfo[0].loanTxnId));
      formData.append("comment", this.newComment);
      formData.append("source", "Super Admin");
      formData.append("mloId", JSON.stringify(null));
      formData.append("userType", this.$userType);

      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/addComment",
        formData);
      if (!this.comments) this.comments = [];
      this.comments.unshift(response.data.comment);
      this.$modal.hide("commentModal");
      this.$store.state.wemloLoader = false;
      this.$snotify.success("Comment added successfully.");
      this.newComment = null;
      this.attachedFiles = [];
      this.countFiles = 0;
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error.response);
    }
  }
  // --------------End of Save Function for Adding Comment-------------/

  //------------------------Displaying Attachment-----------------------/
  public displayDocument(allFiles, isDeleteButton, deleteData) {
    this.$refs.dfd["setInitialValuesForLocalFiles"](
      allFiles,
      isDeleteButton,
      deleteData
    );
  }

  // ---------------Save Function for Attachment-------------/
  storeAttachments(event) {
    this.attachedFiles = [];
    Object.values(event.target.files).forEach(element => {
      this.attachedFiles.push(element);
      this.localFiles = this.attachedFiles;
    });
    this.countFiles = this.attachedFiles.length;
    if (event.target.files.length > 0) {
      this.fileChange = true;
    }
  }
  // ------------End Of Save Function for Attachment-----------/

  public showConfirmModal() {
    this.$modal.show("confirmModal");
  }

  public async confirmModalYes() {
    try {
      this.$modal.hide("confirmModal");
      this.$store.state.wemloLoader = true;
      await this.resetAusCaseFileId();
      this.$store.state.wemloLoader = false;
      await this.updateLenderInfo();
      this.$emit('lenderUpdated');
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }

  public confirmModalNo() {
    this.$modal.hide("confirmModal");
  }

  mounted() {
  }
}
